.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 70px;
  margin-top: 10px;
  // box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  // background: linear-gradient(90deg,var(--primary-color) 30%, var(--secondary-color)100%);
}

.containerImg {
  margin-right: 40px;
}

.containerInfo {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
  margin-left: 40px;
}

.offerTitle {
  color: var(--ternary-color);
}