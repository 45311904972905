.submitButton {
  background: var(--ternary-color);
  color: var(--white-color);
  border: 0 solid transparent;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 20px 80px;
  font-size: var(--font-size-lg);
  font-weight: 600;
  text-decoration: none;
  border-radius: 40px;
}

.submitButton:hover {
  background: var(--secondary-color);
  color: var(--black-color);
  transition: .4s;
  text-decoration: none;
}