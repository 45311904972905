.containerCards {
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 10px;
  transition: 0.2s, box-shadow 0.3s;
  height: auto;
  width: 25rem;
  padding: 1.5rem;
  background-color: var(--ternary-color);
  display: flex;
  margin-top: 17px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.headingBox {
  display: flex;
  margin-bottom: 23px;
  color: var(--white-color);
  padding: 10px;
}

.button {
  margin-bottom: 8px;
}

.button:hover {
  background-color: white !important;
  color: var(--black-color) !important;
}

.containerCards:hover {
  box-shadow: 0 15px 30px 0 rgb(89 98 115 / 15%);
  transform: translate(0,-10px);
}