.column5 {
  padding-right: 20px;
  padding-left: 20px;
  transition: transform .2s;
  transition: transform .2s,-webkit-transform .2s;
}

.column5:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.wCol {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  margin-top: 20px;
  line-height: 32px;
}

.images {
  margin-right: 0;
  margin-bottom: 20px;
  margin-left: 0;
  text-align: center;
  width: 100%;
}

.headingBox {
  margin-top: 10px;
  color: var(--white-color);
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 12px 0px;
}

.containerColumns {
  padding-right: 20px;
  padding-left: 20px;
  -webkit-transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s,-webkit-transform .2s;
}

.textBox {
  font-family: 'Dinpro tr',sans-serif;
  color: var(--white-color);
}

.divBlock {
  display: flex;
  max-height: 150px;
  max-width: 150px;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
}
