.grade {
  background-color: var(--page-background);
}

.noPaddingTop {
  margin-right: auto;
  margin-left: auto;
  padding-top: 0px;
  padding-bottom: 10px;
  align-items: center;
  background-color: var(--page-background);
}

.declaracionPlansWrapper {
  position: static;
  max-width: 1300px;
  margin: 20px auto 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.sectionIntro {
  display: flex;
  max-width: 100%;
  margin-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  flex-direction: column; 
  align-items: center;
}

.headingPlans {
  color: var(--ternary-color);
  margin-bottom: 17px;
  margin-top: 5px;
  text-transform: uppercase;
}

.title {
  color: var(--ternary-color);
  line-height: 23px;
}

.price {
  margin-bottom: 10px;
  margin-left: 5px;
}

.caps {
  color: var(--ternary-color);
  text-align: center;
  margin: 15px;
}

.wColumns {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.wCol {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.wCol6 {
  width: 50%;
}

.wCol8 {
  width: 100%;
}

.estandarAndExpres {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.containerTextImportant {
  display: flex;
  flex-direction: column;
  width: 63%;
  margin-left: auto;
  margin-right: 22px;
  box-shadow: 0 6px 12px 0 rgb(89 98 115 / 50%);
  transition: box-shadow 0.3s;
  border-radius: 14px;
  margin-bottom: 28px;
}

.headingUpBox {
  display: flex;
  height: 40px;
  margin-right: 35px;
  margin-bottom: 16px;
  margin-left: 35px;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  text-align: center;
}

.containerTextEnd {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.itemsSpan {
  border-bottom: none;
  border-radius: 10px;
}

.divImportant {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  color: var(--white-color);
  background: linear-gradient(90deg,var(--ternary-color) 50%, var(--secondary-color));
}