.bigMessage {
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.grade {
  background-image: linear-gradient(90deg,var(--ternary-color) 60%, var(--secondary-color) 100%);
  color: var(--white-color);
}

.wrapper {
  z-index: auto;
  width: 100%;
  text-align: center;
}

.ctaBotonEmpieza {
  margin-bottom: 25px;
  transition: .2s;
  line-height: 1.2;
}