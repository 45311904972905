.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  color: var(--ternary-color);
  margin-top: 50px;
  padding: 10px 20px 10px 0px;
}

.featureCards {
  display: flex;
  grid-column-gap: 30px;
  margin-bottom: 10px;
}

.question {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.questionMark {
  font-family: monospace;
  color: var(--ternary-color);
  margin-top: 54px;
  font-weight: 100;
}