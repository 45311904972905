.pricingV1Card {
  position: relative;
  display: flex;
  overflow: hidden;
  min-height: 600px;
  margin-right: 15px;
  margin-bottom: 32px;
  margin-left: 15px;
  padding: 40px 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 14px;
  background-color: var(--primary-color);
  box-shadow: 0 6px 12px 0 rgb(89 98 115 / 50%);
  transition: box-shadow 0.3s;
  text-align: center;
  flex: 1;
}

.pricingV1Badge {
  position: absolute;
  top: -55px;
  right: -70px;
  display: flex;
  width: 150px;
  height: 123px;
  padding-bottom: 7px;
  padding-left: 1px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #253f6f;
  transform: rotate(45deg);
  color: #fff;
}

.headingBox {
  color: var(--ternary-color);
  margin-top: 10px;
}

.smallDivider {
  width: 200px;
  height: 4px;
  margin-bottom: 20px;
  border: 0 #000;
}

.pricingTag {
  display: flex;
  margin-top: -13px;
  padding-bottom: 10px;
  justify-content: center;
  align-items: flex-start;
  font-size: 64px;
  line-height: 44px;
}

.discountedPriceTag {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: var(--font-size-lg);
  line-height: 44px;
}

.pricingCurrency {
  margin-right: 6px;
  color: #7a8292;
  font-size: 20px;
  line-height: 20px;
}

.discountedPriceCurrency{
  margin-right: 4px;
  color: #7a8292;
  font-size: var(--font-size-sm);
  line-height: 32px;
}

.declarationPrice {
  display: flex;
  align-items: flex-start;
  color: var(--ternary-color);
  font-size: 50px;
  font-weight: 500;
}

.declarationDiscountedPrice {
  display: flex;
  align-items: flex-start;
  color: var(--ternary-color);
  font-size: var(--font-size-md);
  font-weight: 500;
  text-decoration: line-through;
}

.moveUpCents {
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
}

.discountedPriceCents {
  font-size: var(--font-size-sm);
  font-weight: 600;
  line-height: 40px;
  text-decoration: line-through;
}

.pricingFeatures {
  display: flex;
  min-height: 220px;
  margin-top: 0;
  padding-top: 0;
  flex-direction: column;
  align-items: center;
  border: 1px #000;
}

.pricingListItem {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  flex-direction: column;
  padding-right: 14px;
}

.upper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.button:hover {
  background-color: var(--secondary-color);
}

.submitButton {
  background: var(--ternary-color);
  color: var(--white-color);
  border: 0 solid transparent;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 20px 80px;
  font-size: var(--font-size-lg);
  font-weight: 600;
  text-decoration: none;
  border-radius: 40px;
}

.submitButton:hover {
  background: var(--secondary-color);
  color: var(--black-color);
  transition: .4s;
  text-decoration: none;
}