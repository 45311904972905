.container {
  display: flex;
  background-image: linear-gradient(270deg, var(--primary-color) -50%, var(--ternary-color) 50%);
  flex-direction: column;
}

.containerVideoAndTitle {
  display: flex;
  justify-content: space-evenly;
  padding: 45px 64px 35px;
  align-items: center;
}

.header {
  margin-bottom: 20px;
  color: var(--white-color);
  line-height: 1.3;
  text-align: center;
  width: 40%;
}
.youtubevideo {
  border-radius: 15px;
}

.containerTextBlock {
  display: flex;
  flex-direction: column;
  padding: 2px 75px 26px;
  width: 80%;
  align-self: center;
  text-align-last: center;
}

.textBlock {
  margin-bottom: 0;
  color: var(--white-color);
  width: 81%;
  align-self: center;
  line-height: 40px;
}

.containerButton {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}
